













































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.preview-container {
    /* margin 3rem auto 1rem
    border-radius 6px
    box-shadow 0 0 15px rgba(0, 0, 0, .1) */
    .width-slider {
        width: 150px;
        position: absolute;
        top: 0;
        right: 2rem;
        display: none;
    }
}

.showDivider.form-container {
    margin-bottom: 2rem;
}

.loading-mask {
    width: 100vw;
    height: 100vh;
}

/deep/ .el-input.is-disabled .el-input__inner {
    text-align: left;
}
