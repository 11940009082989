
























































































































































































































































































































































































































































































































































.h-transfer {
    text-align: left;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 2999;
    line-height: 32px;

    > .mask {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
    }

    .el-tabs--border-card {
        box-shadow: none;
    }

    .el-tabs__content {
        overflow: visible;
        min-height: 250px;
    }

    .el-tabs__nav {
        width: 100%;
        display: flex;

        > .el-tabs__item {
            flex-grow: 1;
        }
    }

    &.single-tab .el-tabs__item {
        text-align: center;
        background: #f5f7fa !important;
        border-bottom: 1px solid #e4e7ed;
        border-right-width: 0px;
    }

    .el-tree-node__content > label.el-checkbox {
        // position: absolute;
        // right: 0;
    }

    .searchResPane {
        position: absolute;
        overflow-y: auto;
        z-index: 99;
        left: 0;
        top: 100%;
        width: 100%;
        height: 100%;
        background: white;
        border: 1px solid #dcdfe6;
        transition: top 0.5s;

        &.active {
            top: 0;
        }

        .hidden-tag {
            color: #999;
            font-size: 12px;
            text-align: right;
            padding-top: 4px;
            padding-right: 12px;
            cursor: pointer;

            &:hover {
                color: #66b1ff;
            }
        }

        .item {
            padding: 4px 14px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            line-height: 1.5;

            &:hover {
                background-color: #ecf5ff;
                color: #66b1ff;
                cursor: pointer;
            }

            .search-res-tip {
                font-size: 12px;
                color: #999;
                max-width: 280px;
            }
        }
    }

    .enough-mask {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        color: white;
        font-size: 16px;
        z-index: 100;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        letter-spacing: 4px;
    }

    .p-center {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .transfer__content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 800px;
        background: white;
        overflow: hidden;
        border-radius: 4px;
    }

    .transfer__header {
        margin-bottom: 6px;
        border-bottom: 1px solid #e8e8e8;
        background: #fff; /* #565656; */
        padding: 6px 24px;

        /* color: white; */
        .el-icon-close {
            cursor: pointer;
            float: right;
            margin-top: 10px;
        }
    }

    .transfer__body {
        padding: 12px 0;
        display: flex;
        justify-content: space-around;
    }

    .transfer-pane {
        width: 360px;
    }

    .search-input input {
        border: 1px solid #DCDFE6 !important;

        &:focus {
            border-color: #409EFF !important;
        }
    }

    .transfer-pane__tools {
        margin-bottom: 10px;
        height: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        span:last-child {
            cursor: pointer;
        }
    }

    .transfer-pane__body {
        position: relative;
        width: 100%;
        height: 330px !important;
        overflow: hidden;
        font-size: 14px;

        >>> .el-scrollbar__view {
            height: 100%;
        }

        .el-tabs__item {
            height: 26px;
            line-height: 26px;
        }
    }

    .transfer-icons {
        display: flex;
        flex-direction: column;
        justify-content: center;

        i {
            margin-top: 20px;
            margin-bottom: 20px;
            cursor: pointer;
            font-size: 20px;
            color: #696969;

            &:hover {
                transform: scale(1.1);
            }
        }
    }

    .tree-node {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        padding-right: 8px;

        .node-label {
            max-width: 250px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: inline-block;
        }

        .node-checkbox {
            position: absolute;
            right: 0;
        }

        i {
            &:hover {
                color: #1485f8;
                cursor: pointer;
            }

            font-size: 10px;
        }
    }

    .right-pane {
        box-sizing: border-box;
        overflow-x: hidden;
        height: 290px;
        margin-bottom: 10px;

        .selected-item {
            padding: 0px 12px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            &:hover {
                background-color: #F5F7FA;
            }

            span {
                max-width: 90%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            i {
                &:hover {
                    color: #1485f8;
                    cursor: pointer;
                }
            }
        }
    }

    .dot {
        width: 2px;
        height: 2px;
        display: inline-block;
        border-radius: 50%;
        background: #4caf50;
    }

    .text-ellipsis {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
