


































































































































































































































































































































































































































































































.tags {
    .input-box.as-input {
        border: 1px solid #DCDFE6;
        padding-left: 6px;
        font-size: 12px;
        min-height: 32px;
        line-height: 30px;
        border-radius: 4px;
        background: white;
        color: #606266;
        cursor: pointer;
    }

    .org-tag {
        margin-right: 6px;
        max-width: 6rem;
        overflow: hidden;
        text-overflow: ellipsis;
        position: relative;
        padding-right: 1rem;
        vertical-align: middle;

        >>> .el-tag__close {
            position: absolute;
            right: 2px;
            top: 50%;
            margin-top: -7px;
        }
    }
}
