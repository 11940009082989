.fc-table-box {
  margin-bottom: 0px;
}
.fc-table-box .row-action {
  display: flex;
  justify-content: center;
  align-items: center;
}
.fc-table-box .row-action .el-icon-delete {
  position: absolute;
  opacity: 0;
  z-index: -1;
  transform: translate(-50%, -50%);
  cursor: pointer;
}
.fc-table-box .actions {
  text-align: center;
  border: 1px solid #ebeef5;
  border-top: none;
}
.fc-table-box .list-summary {
  line-height: 24px;
  overflow: hidden;
  border: 1px solid #e5e5e5;
  border-top: none;
}
.fc-table-box.list .list-row {
  padding: 18px 0 10px;
  text-align: left;
  border-bottom: 1px solid #e5e5e5;
  position: relative;
}
.fc-table-box.list .list-row:hover .delete-btn {
  display: block;
}
.fc-table-box.list .list-row .delete-btn {
  position: absolute;
  right: 10px;
  top: 20px;
  z-index: 999;
  cursor: pointer;
  display: none;
}
.fc-table-box.list .list-row .delete-btn:hover {
  color: #000;
}
.fc-table-box.list .list-row .row-item {
  margin-bottom: 18px;
  position: relative;
}
.fc-table-box.list .list-row .row-item.error .error-tip {
  top: 74%;
  z-index: 1;
}
.fc-table-box.list .list-row .row-item.error >>> .el-input__inner {
  border-color: #f56c6c;
}
.fc-table-box.list .list-row .row-item > div:first-child {
  text-align: right;
  vertical-align: middle;
  float: left;
  font-size: 14px;
  color: #606266;
  line-height: 32px;
  padding: 0 12px 0 0;
  box-sizing: border-box;
}
.fc-table-box.list .error-tip {
  font-size: 12px;
  padding-left: 6px;
  color: #f56c6c;
  position: absolute;
  left: 100px;
  top: 0;
  z-index: -1;
  transition: bottom 0.3s;
  min-height: auto;
}
.fc-table-box.table >>> .el-table__row:hover .index {
  display: none;
}
.fc-table-box.table >>> .el-table__row:hover .el-icon-delete {
  z-index: 9;
  opacity: 1;
}
.fc-table-box.table >>> .el-input__inner,
.fc-table-box.table >>> .el-textarea__inner {
  width: 100%;
  border: none;
  text-align: left;
  padding-right: 10px;
  padding-left: 10px;
}
.fc-table-box.table >>> .el-date-editor .el-input__prefix {
  left: -10px;
  top: 1px;
}
.fc-table-box.table >>> .el-date-editor .el-input__suffix {
  top: 1px;
  right: 0;
}
.fc-table-box.table >>> .el-input-number {
  width: 100%;
}
.fc-table-box.table >>> .el-upload--text {
  width: 100%;
  height: 100%;
  padding-top: 6px;
  white-space: nowrap;
}
.fc-table-box.table >>> .input-box {
  border: none !important;
  min-height: 40px !important;
  padding-left: 0 !important;
}
.fc-table-box.table >>> .el-table .el-table__body td {
  padding: 0;
  background: #fff !important;
}
.fc-table-box.table >>> .el-table .el-table__body td .error-tip {
  font-size: 12px;
  padding-left: 6px;
  color: #f56c6c;
}
.fc-table-box.table >>> .el-table .el-table__body td .cell {
  position: relative;
}
.fc-table-box.table >>> .el-table .el-table__body td .cell > div {
  width: 100%;
  min-height: 40px;
}
.fc-table-box.table >>> .el-table .el-table__body td:not(:first-child) {
  vertical-align: top;
}
.fc-table-box.table >>> .el-table .el-table__body td:not(:first-child)::after,
.fc-table-box.table >>> .el-table .el-table__body td:not(:first-child)::before {
  content: '';
  width: 10px;
  height: 10px;
  background: #fff;
  position: absolute;
  border: 2px solid transparent;
  transition: border-color 0.3s;
}
.fc-table-box.table >>> .el-table .el-table__body td:not(:first-child)::after {
  border-top: none;
  border-right: none;
  left: 0;
  bottom: 0;
}
.fc-table-box.table >>> .el-table .el-table__body td:not(:first-child)::before {
  border-bottom: none;
  border-left: none;
  right: 0;
  top: 0;
}
.fc-table-box.table >>> .el-table .el-table__body td:not(:first-child):hover::after,
.fc-table-box.table >>> .el-table .el-table__body td:not(:first-child):hover::before {
  border-color: #f00;
}
.fc-table-box.table >>> .fc-org-select {
  position: relative;
}
.fc-table-box.table >>> .el-slider {
  padding-left: 10px;
}
.fc-table-box.table >>> .el-upload-list--text {
  position: fixed;
  margin-left: -6px;
  background: #fff;
  box-shadow: 2px 2px 8px 2px rgba(0,0,0,0.1);
  max-width: 250px;
  transition: margin-top 0.3s;
  display: none;
  z-index: 9;
}
.fc-table-box.table >>> .el-upload-list--text.show {
  display: block;
}
/*# sourceMappingURL=src/components/jpgflow/FormControls/FormInputTable/index.css.map */