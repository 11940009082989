@import "@/styles/variables.scss"; 
































































































































@import "../../../../styles/global.scss"; //* form-item-wrapper已引入，还需要重复引入吗？ *//

.full-width-input {
    width: 100% !important;
}
