@import "@/styles/variables.scss"; 


























































































































































































































































































































































































































































































































.dept {
    height: 100%;
    width: 100%;

    .el-card__header {
        padding: 10px;
    }

    .el-card__body {
        padding: 10px;
        max-height: 520px;
        overflow: auto;
    }
}

.userDialog {
    .el-dialog__body {
        padding: 10px 0px 0px 10px;
        color: #606266;
        font-size: 14px;
        word-break: break-all;
    }

    .el-main {
        padding: 20px 20px 5px 20px;

        .el-pagination {
            margin-top: 5px;
        }
    }
}

.user-table .el-table th.el-table__cell {
    background-color: transparent !important;
}
