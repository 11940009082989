
































































































































































































































































































































































































































































































































































.fc-table-box {
    margin-bottom: 0px;

    .row-action {
        display: flex;
        justify-content: center;
        align-items: center;

        .el-icon-delete {
            position: absolute;
            opacity: 0;
            z-index: -1;
            transform: translate(-50%, -50%);
            cursor: pointer;
        }
    }

    .actions {
        text-align: center;
        border: 1px solid #EBEEF5;
        border-top: none;
    }

    .list-summary {
        line-height: 24px;
        overflow: hidden;
        border: 1px solid #e5e5e5;
        border-top: none;
    }

    &.list {
        .list-row {
            padding: 18px 0 10px;
            text-align: left;
            border-bottom: 1px solid #e5e5e5;
            position: relative;

            &:hover .delete-btn {
                display: block;
            }

            .delete-btn {
                position: absolute;
                right: 10px;
                top: 20px;
                z-index: 999;
                cursor: pointer;
                display: none;

                &:hover {
                    color: #000;
                }
            }

            .row-item {
                margin-bottom: 18px;
                position: relative;

                &.error {
                    .error-tip {
                        top: 74%;
                        z-index: 1;
                    }

                    >>> .el-input__inner {
                        border-color: #F56C6C;
                    }
                }

                > div {
                    &:first-child {
                        text-align: right;
                        vertical-align: middle;
                        float: left;
                        font-size: 14px;
                        color: #606266;
                        line-height: 32px;
                        padding: 0 12px 0 0;
                        box-sizing: border-box;
                    }
                }
            }
        }

        .error-tip {
            font-size: 12px;
            padding-left: 6px;
            color: #f56c6c;
            position: absolute;
            left: 100px;
            top: 0;
            z-index: -1;
            transition: bottom 0.3s;
            min-height: auto;
        }
    }
}

.fc-table-box.table >>> {
    // 索引和删除按钮切换
    .el-table__row:hover {
        .index {
            display: none;
        }

        .el-icon-delete {
            z-index: 9;
            opacity: 1;
        }
    }

    // 去除输入框边框
    .el-input__inner, .el-textarea__inner {
        width: 100%;
        border: none;
        text-align: left;
        padding-right: 10px;
        padding-left: 10px;
    }

    .el-date-editor {
        .el-input__prefix {
            left: -10px;
            top: 1px;
        }

        .el-input__suffix {
            top: 1px;
            right: 0;
        }
    }

    .el-input-number {
        width: 100%;
    }

    // 下载按钮
    .el-upload--text {
        width: 100%;
        height: 100%;
        padding-top: 6px;
        white-space: nowrap;
    }

    // 组织机构按钮
    .input-box {
        border: none !important;
        min-height: 40px !important;
        padding-left: 0 !important;
    }

    .el-table .el-table__body {
        td {
            padding: 0;
            background: #FFF !important;

            .error-tip {
                font-size: 12px;
                padding-left: 6px;
                color: #f56c6c;
            }

            .cell {
                position: relative;

                > div {
                    width: 100%;
                    min-height: 40px;
                }
            }
        }

        td:not(:first-child) {
            vertical-align: top;

            &::after, &::before {
                content: '';
                width: 10px;
                height: 10px;
                background: white;
                position: absolute;
                border: 2px solid transparent;
                transition: border-color 0.3s;
            }

            &::after {
                border-top: none;
                border-right: none;
                left: 0;
                bottom: 0;
            }

            &::before {
                border-bottom: none;
                border-left: none;
                right: 0;
                top: 0;
            }

            &:hover {
                &::after, &::before {
                    border-color: red;
                }
            }
        }
    }

    .fc-org-select {
        position: relative;
    }

    .el-slider {
        padding-left: 10px;
    }

    .el-upload-list--text {
        position: fixed;
        margin-left: -6px;
        background: white;
        box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.1);
        max-width: 250px;
        transition: margin-top 0.3s;
        display: none;
        z-index: 9;

        &.show {
            display: block;
        }
    }
}
