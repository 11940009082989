@import "@/styles/variables.scss"; 


























































































































































































.association-form-item{
    padding: 5px 5px 0 5px;
    border: 1px dashed #d3d3d3;
    .header{
        // padding-bottom: 5px;
        display: flex;
        justify-content: flex-end;
        // border-bottom: 1px solid #d3d3d3;
    }
}
