@import "@/styles/variables.scss"; 























































































































































































@import "../../../../styles/global.scss";

.design-time-bottom-margin {
    margin-bottom: 5px;
}

.field-wrapper {
    position: relative;

    .field-action {
        position: absolute;
        //bottom: -24px;
        //   bottom: 0;
        top: 0;
        right: 100px;
        height: 22px;
        line-height: 22px;
        background: $--color-primary;
        z-index: 9;

        i {
            font-size: 14px;
            color: #fff;
            margin: 0 5px;
            cursor: pointer;
        }
    }

    .drag-handler {
        position: absolute;
        top: 0;
        //bottom: -22px;  /* 拖拽手柄位于组件下方，有时无法正常拖动，原因未明？？ */
        right: -1px;
        height: 20px;
        line-height: 20px;
        //background: $--color-primary;
        z-index: 9;

        i {
            font-size: 12px;
            font-style: normal;
            color: #fff;
            margin: 4px;
            cursor: move;
        }

        &:hover {
            //opacity: 1;
            background: $--color-primary;
        }
    }
}

.static-content-item {
    min-height: 20px;
    display: flex; /* 垂直居中 */
    align-items: center; /* 垂直居中 */

    ::v-deep .el-divider--horizontal {
        margin: 0;
    }
}

.el-form-item.selected,
.static-content-item.selected {
    outline: 2px solid $--color-primary;
}
