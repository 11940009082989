@import "@/styles/variables.scss"; 






























































































































































































































































































































































































































































































































































































































.el-input-group__append {
  border-left: 0;
  background: #409eff;
  color: #fff;
  height: 36px;
}
.el-input--small .el-input__inner {
  height: 36px;
  line-height: 36px;
}
.el-input.is-disabled .el-input__inner {
  background-color: #fff;
  height: 36px;
  line-height: 36px;
  cursor: pointer;
}
// .vxe-table--render-default .vxe-body--row.row--stripe {
//   background-color: #e3f1ff;
// }
.vxe-table--header {
  background-color: #e3f1ff;
}
