@import "@/styles/variables.scss"; 







































































































































































































































































































































































































































































::v-deep .el-dialog__body {
    height: 70vh;
    overflow: auto;
}
.width-100 {
    width: 100%;
}
.box-card .post-tags {
    height: 185px;
    overflow-y: auto;
    .tips {
        // font-size: 12px;
        text-align: center;
        color: #909399;
    }
}
.post-tags {
    // display: flex;
    // flex-wrap: wrap;
    margin: -5px;

    .post-tag {
        margin: 5px !important;
    }
}
