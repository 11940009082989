.h-transfer {
  text-align: left;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 2999;
  line-height: 32px;
}
.h-transfer > .mask {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
}
.h-transfer .el-tabs--border-card {
  box-shadow: none;
}
.h-transfer .el-tabs__content {
  overflow: visible;
  min-height: 250px;
}
.h-transfer .el-tabs__nav {
  width: 100%;
  display: flex;
}
.h-transfer .el-tabs__nav > .el-tabs__item {
  flex-grow: 1;
}
.h-transfer.single-tab .el-tabs__item {
  text-align: center;
  background: #f5f7fa !important;
  border-bottom: 1px solid #e4e7ed;
  border-right-width: 0px;
}
.h-transfer .searchResPane {
  position: absolute;
  overflow-y: auto;
  z-index: 99;
  left: 0;
  top: 100%;
  width: 100%;
  height: 100%;
  background: #fff;
  border: 1px solid #dcdfe6;
  transition: top 0.5s;
}
.h-transfer .searchResPane.active {
  top: 0;
}
.h-transfer .searchResPane .hidden-tag {
  color: #999;
  font-size: 12px;
  text-align: right;
  padding-top: 4px;
  padding-right: 12px;
  cursor: pointer;
}
.h-transfer .searchResPane .hidden-tag:hover {
  color: #66b1ff;
}
.h-transfer .searchResPane .item {
  padding: 4px 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 1.5;
}
.h-transfer .searchResPane .item:hover {
  background-color: #ecf5ff;
  color: #66b1ff;
  cursor: pointer;
}
.h-transfer .searchResPane .item .search-res-tip {
  font-size: 12px;
  color: #999;
  max-width: 280px;
}
.h-transfer .enough-mask {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  color: #fff;
  font-size: 16px;
  z-index: 100;
  height: 100%;
  background: rgba(0,0,0,0.5);
  letter-spacing: 4px;
}
.h-transfer .p-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.h-transfer .transfer__content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 800px;
  background: #fff;
  overflow: hidden;
  border-radius: 4px;
}
.h-transfer .transfer__header {
  margin-bottom: 6px;
  border-bottom: 1px solid #e8e8e8;
  background: #fff; /* #565656; */
  padding: 6px 24px;
/* color: white; */
}
.h-transfer .transfer__header .el-icon-close {
  cursor: pointer;
  float: right;
  margin-top: 10px;
}
.h-transfer .transfer__body {
  padding: 12px 0;
  display: flex;
  justify-content: space-around;
}
.h-transfer .transfer-pane {
  width: 360px;
}
.h-transfer .search-input input {
  border: 1px solid #dcdfe6 !important;
}
.h-transfer .search-input input:focus {
  border-color: #409eff !important;
}
.h-transfer .transfer-pane__tools {
  margin-bottom: 10px;
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.h-transfer .transfer-pane__tools span:last-child {
  cursor: pointer;
}
.h-transfer .transfer-pane__body {
  position: relative;
  width: 100%;
  height: 330px !important;
  overflow: hidden;
  font-size: 14px;
}
.h-transfer .transfer-pane__body >>> .el-scrollbar__view {
  height: 100%;
}
.h-transfer .transfer-pane__body .el-tabs__item {
  height: 26px;
  line-height: 26px;
}
.h-transfer .transfer-icons {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.h-transfer .transfer-icons i {
  margin-top: 20px;
  margin-bottom: 20px;
  cursor: pointer;
  font-size: 20px;
  color: #696969;
}
.h-transfer .transfer-icons i:hover {
  transform: scale(1.1);
}
.h-transfer .tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
.h-transfer .tree-node .node-label {
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
}
.h-transfer .tree-node .node-checkbox {
  position: absolute;
  right: 0;
}
.h-transfer .tree-node i {
  font-size: 10px;
}
.h-transfer .tree-node i:hover {
  color: #1485f8;
  cursor: pointer;
}
.h-transfer .right-pane {
  box-sizing: border-box;
  overflow-x: hidden;
  height: 290px;
  margin-bottom: 10px;
}
.h-transfer .right-pane .selected-item {
  padding: 0px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.h-transfer .right-pane .selected-item:hover {
  background-color: #f5f7fa;
}
.h-transfer .right-pane .selected-item span {
  max-width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.h-transfer .right-pane .selected-item i:hover {
  color: #1485f8;
  cursor: pointer;
}
.h-transfer .dot {
  width: 2px;
  height: 2px;
  display: inline-block;
  border-radius: 50%;
  background: #4caf50;
}
.h-transfer .text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
/*# sourceMappingURL=src/components/form/FormControls/OrgTransfer/index.css.map */