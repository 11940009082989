@import "@/styles/variables.scss"; 





























































































































































































































































































































































































































li,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.success-3-top {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

li > span {
  display: inline-block;
  width: 82px;
  text-align: right;
}
.success-3-top-ul {
  height: 116px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  li {
    // width: 13vw;
    // overflow: hidden;
    white-space: nowrap;
    // text-overflow: ellipsis;
  }
}
.success-3-bottom {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-top: 10px;
}
.line {
  width: 100%;
  height: 1px;
  background: #f6f8f9;
  margin-top: 3vh;
}
.select-right {
  display: flex;
  justify-content: space-between;
}
.selsect-semester {
  width: 158px;
  height: 30px;
  display: flex;
  border: 1px solid #0894ec;
  line-height: 30px;
  color: #0894ec;
  font-weight: bold;
  & > li {
    flex: 1;
    text-align: center;
    &.active {
      background: #0894ec;
      color: white;
    }
  }
}
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 321px !important;
}
.options {
  margin-top: 6vh;
  position: relative;
  left: 18px;
  top: 30px;
  z-index: 9;
  // width: 96%;
}

.component {
  transform: translateY(-50px);
}
