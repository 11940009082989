@import "@/styles/variables.scss"; 








































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.el-form ::v-deep .el-row {
  padding: 8px;
  display: flex;
  flex-wrap: wrap;
}
