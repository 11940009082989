@import "@/styles/variables.scss"; 































































































































































































































































































































































































































































































































































































@import "../../../../styles/global.scss"; //* form-item-wrapper已引入，还需要重复引入吗？ *//

.full-width-input {
    width: 100% !important;
}

.dynamicPseudoAfter ::v-deep .el-upload.el-upload--text {
    color: $--color-primary;
    font-size: 12px;
    .el-icon-plus:after {
        content: var(--select-file-action);
    }
}

.hideUploadDiv {
    ::v-deep div.el-upload--picture-card {
        /* 隐藏最后的图片上传按钮 */
        display: none;
    }

    ::v-deep div.el-upload--text {
        /* 隐藏最后的文件上传按钮 */
        display: none;
    }

    ::v-deep div.el-upload__tip {
        /* 隐藏最后的文件上传按钮 */
        display: none;
    }
}

.upload-file-list {
    font-size: 12px;

    .file-action {
        color: $--color-primary;
        margin-left: 5px;
        margin-right: 5px;
        cursor: pointer;
    }
}
